import { CompanyMetadata } from 'types';
import { CompanyName } from 'utils/constants';

export const offerCompanies: {
  [key in CompanyName]: CompanyMetadata;
} = {
  [CompanyName.MICROSOFT]: {
    displayName: 'Microsoft',
    logoFilename: 'microsoft.png',
    maxLogoSize: 100,
  },
  [CompanyName.META]: {
    displayName: 'Meta',
    logoFilename: 'meta.png',
    maxLogoSize: 100,
  },
  [CompanyName.WAYFAIR]: {
    displayName: 'Wayfair',
    logoFilename: 'wayfair.png',
    maxLogoSize: 100,
  },
  [CompanyName.SERVICE_NOW]: {
    displayName: 'ServiceNow',
    logoFilename: 'servicenow.png',
    maxLogoSize: 100,
  },
  [CompanyName.LINKEDIN]: {
    displayName: 'LinkedIn',
    logoFilename: 'linkedin.png',
    maxLogoSize: 100,
  },
  [CompanyName.BANK_OF_AMERICA]: {
    displayName: 'Bank of America',
    logoFilename: 'bankofamerica.png',
    maxLogoSize: 100,
  },
  [CompanyName.DOORDASH]: {
    displayName: 'DoorDash',
    logoFilename: 'doordash.png',
    maxLogoSize: 100,
  },
  [CompanyName.ROBINHOOD]: {
    displayName: 'Robinhood',
    logoFilename: 'robinhood.png',
    maxLogoSize: 100,
  },
  [CompanyName.AMAZON]: {
    displayName: 'Amazon',
    logoFilename: 'amazon.png',
    maxLogoSize: 100,
  },
  [CompanyName.GODADDY]: {
    displayName: 'GoDaddy',
    logoFilename: 'godaddy.png',
    maxLogoSize: 100,
  },
  [CompanyName.ADOBE]: {
    displayName: 'Adobe',
    logoFilename: 'adobe.png',
    maxLogoSize: 100,
  },
  [CompanyName.PAYPAL]: {
    displayName: 'PayPal',
    logoFilename: 'paypal.png',
    maxLogoSize: 100,
  },
  [CompanyName.HUBSPOT]: {
    displayName: 'HubSpot',
    logoFilename: 'hubspot.png',
    maxLogoSize: 100,
  },
  [CompanyName.GOLDMAN_SACHS]: {
    displayName: 'Goldman Sachs',
    logoFilename: 'goldmansachs.png',
    maxLogoSize: 100,
  },
  [CompanyName.ASANA]: {
    displayName: 'Asana',
    logoFilename: 'asana.png',
    maxLogoSize: 100,
  },
  [CompanyName.WAYMO]: {
    displayName: 'Waymo',
    logoFilename: 'waymo.png',
    maxLogoSize: 100,
  },
  [CompanyName.SLACK]: {
    displayName: 'Slack',
    logoFilename: 'slack.png',
    maxLogoSize: 100,
  },
  [CompanyName.HEROKU]: {
    displayName: 'Heroku',
    logoFilename: 'heroku.png',
    maxLogoSize: 100,
  },
  [CompanyName.SHOPIFY]: {
    displayName: 'Shopify',
    logoFilename: 'shopify.png',
    maxLogoSize: 100,
  },
  [CompanyName.MONGODB]: {
    displayName: 'MongoDB',
    logoFilename: 'mongo.png',
    maxLogoSize: 100,
  },
  [CompanyName.PLAID]: {
    displayName: 'Plaid',
    logoFilename: 'plaid.png',
    maxLogoSize: 100,
  },
  [CompanyName.EBAY]: {
    displayName: 'eBay',
    logoFilename: 'ebay.png',
    maxLogoSize: 100,
  },
  [CompanyName.EXPEDIA]: {
    displayName: 'Expedia',
    logoFilename: 'expedia.png',
    maxLogoSize: 100,
  },
  [CompanyName.ORACLE]: {
    displayName: 'Oracle',
    logoFilename: 'oracle.png',
    maxLogoSize: 100,
  },
  [CompanyName.NIANTIC]: {
    displayName: 'Niantic',
    logoFilename: 'niantic.png',
    maxLogoSize: 80,
  },
  [CompanyName.GRAMMARLY]: {
    displayName: 'Grammarly',
    logoFilename: 'grammarly.png',
    maxLogoSize: 100,
  },
  [CompanyName.MORGAN_STANLEY]: {
    displayName: 'Morgan Stanley',
    logoFilename: 'morganstanley.png',
    maxLogoSize: 100,
  },
  [CompanyName.MASTERCARD]: {
    displayName: 'Mastercard',
    logoFilename: 'mastercard.png',
    maxLogoSize: 100,
  },
  [CompanyName.VERISK]: {
    displayName: 'Verisk',
    logoFilename: 'verisk.png',
    maxLogoSize: 100,
  },
  [CompanyName.BNY_MELLON]: {
    displayName: 'BNY Mellon',
    logoFilename: 'bnymellon.png',
    maxLogoSize: 100,
  },
  [CompanyName.EXPERIAN]: {
    displayName: 'Experian',
    logoFilename: 'experian.png',
    maxLogoSize: 100,
  },
  [CompanyName.SAP]: {
    displayName: 'SAP',
    logoFilename: 'sap.png',
    maxLogoSize: 100,
  },
  [CompanyName.LIBERTY_MUTUAL_INSURANCE]: {
    displayName: 'Liberty Mutual Insurance',
    logoFilename: 'libertymutualinsurance.png',
    maxLogoSize: 100,
  },
  [CompanyName.LILLY]: {
    displayName: 'Lilly',
    logoFilename: 'lilly.png',
    maxLogoSize: 100,
  },
  [CompanyName.HELMERICH_PAYNE]: {
    displayName: 'Helmerich & Payne',
    logoFilename: 'helmerichpayne.png',
    maxLogoSize: 80,
  },
  [CompanyName.PROOFPOINT]: {
    displayName: 'Proofpoint',
    logoFilename: 'proofpoint.png',
    maxLogoSize: 100,
    isPartTimeOffer: true,
  },
  [CompanyName.MATSON_MONEY]: {
    displayName: 'Matson Money',
    logoFilename: 'matsonmoney.png',
    maxLogoSize: 100,
  },
  [CompanyName.MCDONALDS]: {
    displayName: 'McDonalds',
    logoFilename: 'mcdonalds.png',
    maxLogoSize: 80,
  },
  [CompanyName.BUBBLE_IO]: {
    displayName: 'Bubble.io',
    logoFilename: 'bubbleio.png',
    maxLogoSize: 100,
  },
  [CompanyName.KENCO_GROUP]: {
    displayName: 'Kenco Group',
    logoFilename: 'kencogroup.png',
    maxLogoSize: 100,
  },
  [CompanyName.COINBASE]: {
    displayName: 'Coinbase',
    logoFilename: 'coinbase.png',
    maxLogoSize: 100,
  },
  [CompanyName.FOREFLIGHT]: {
    displayName: 'ForeFlight',
    logoFilename: 'foreflight.png',
    maxLogoSize: 80,
  },
  [CompanyName.QUALCOMM]: {
    displayName: 'Qualcomm',
    logoFilename: 'qualcomm.png',
    maxLogoSize: 80,
  },
  [CompanyName.PALANTIR]: {
    displayName: 'Palantir',
    logoFilename: 'palantir.png',
    maxLogoSize: 80,
  },
};
