import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Tree } from 'components/tree/tree';
import { BlogPage } from 'components/blogs';
import { allBlogsFilepaths, allBlogsMetadata } from 'blogs/metadata';
import { buildFileMetadata } from 'utils/file';
import { BlogFileMapping } from 'types';

export const TreeContainer = () => {
  const [metadata, setMetadata] = useState<BlogFileMapping | null>(null);

  useEffect(() => {
    setMetadata(buildFileMetadata(allBlogsFilepaths, allBlogsMetadata));
  }, []);

  return (
    <div>
      {metadata != null && (
        <Routes>
          <Route path="/" element={<Tree metadata={metadata} />} />
          <Route
            path="blogs/:filename"
            element={<BlogPage metadata={metadata} />}
          />
        </Routes>
      )}
    </div>
  );
};
