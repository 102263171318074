import { BlogMetadata } from 'types';

export const allBlogsMetadata: BlogMetadata[] = [
  {
    name: "M1-2023-04-30-developer-productivity",
    title: "Developer productivity hacks",
    author: "Khoa Le",
    description: "Bootstrap your productivity with terminal setup and command usages",
    banner: "https://i.ytimg.com/vi/ulEr4zSHL-c/maxresdefault.jpg",
    date: "2023-04-30T04:00:00.000Z",
    tags: ["technical"]
  },
  {
    name: "M1-2023-04-30-tools-for-team",
    title: "Tools for team",
    author: "Khoa Le",
    description: "Basic tools and tips for collaboration with your team",
    banner: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRP6f1aZz5SvNRRPfLMN9bspCSsHUlDtLUDSQ&usqp=CAU",
    date: "2023-04-30T04:00:00.000Z",
    tags: ["technical"]
  },
  {
    name: "M1-2023-04-30-using-git",
    title: "Using Git for collaboration",
    author: "Khoa Le",
    description: "Learn basic Git commands to help you collaborate with your group more effectively in the remote settings",
    banner: "https://media.geeksforgeeks.org/wp-content/uploads/20190624140226/distvcs.png",
    date: "2023-04-30T04:00:00.000Z",
    tags: ["technical"]
  },
  {
    name: "M1-2023-07-30-mentorship-half-way-reflection",
    title: "Mentorship half-way look back",
    author: "Khoa Le",
    description: "Reflection on the past 4-5 months running this mentorship program",
    banner: "https://res.cloudinary.com/khoa165/image/upload/v1691083734/portfolio/VietTechBanner.png",
    date: "2023-07-30T04:00:00.000Z",
    tags: ["reflection"]
  },
  {
    name: "M1-2023-08-15-team1-2023",
    title: "Team 1 project - 2023",
    author: "Hieu Vuong",
    contributors: "Quan Le, Trang Vu, Thanh Nguyen",
    description: "Sharing our learnings from working on the group project",
    banner: "https://res.cloudinary.com/khoa165/image/upload/v1703824525/portfolio/Team1-2023.png",
    date: "2023-08-15T04:00:00.000Z",
    tags: ["project"]
  },
  {
    name: "M1-2023-08-15-team2-2023",
    title: "Team 2 project - 2023",
    author: "Khue Tang",
    contributors: "Phuong Cao, Ngoc Doan, Cuong Nguyen",
    description: "Sharing our learnings from working on the group project",
    banner: "https://res.cloudinary.com/khoa165/image/upload/v1711093793/portfolio/Team2-2023.jpg",
    date: "2023-08-15T04:00:00.000Z",
    tags: ["project"]
  },
  {
    name: "M1-2023-08-15-team3-2023",
    title: "Team 3 project - 2023",
    author: "Robin Hoang",
    contributors: "Jenny Quach, Dang Pham, Phuoc Ong",
    description: "Sharing our learnings from working on the group project",
    banner: "https://res.cloudinary.com/khoa165/image/upload/v1703826455/portfolio/Team3-2023.jpg",
    date: "2023-08-15T04:00:00.000Z",
    tags: ["project"]
  },
  {
    name: "M1-2023-12-15-summary-2023",
    title: "Viet Tech 2023 summary",
    author: "Khoa Le",
    description: "How we did the first year of VT mentorship program",
    banner: "https://res.cloudinary.com/khoa165/image/upload/v1711094075/viettech/offers2023.png",
    date: "2023-12-15T05:00:00.000Z",
    tags: ["reflection"]
  },
  {
    name: "M2-2024-03-22-technical-workshops-2024",
    title: "Technical workshops 2024",
    author: "Khoa Le",
    description: "Recordings from 2024 workshops - Git, HTML/CSS, Productivity, MERN",
    date: "2024-03-22T04:00:00.000Z",
    tags: ["resources"]
  },
];
